import axios from 'axios';
import { httpurltest } from '../utils/constants';

export async function connTest() {
    try {
        const responseTest = await axios.get(httpurltest);
        if (responseTest.status < 500) {
            return responseTest.status;
        } else {
            throw new Error(responseTest);
        }
    } catch (error) {
        console.error("connectTest", error);
    }
}