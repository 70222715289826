import { isLine, isGroup, isSubGroup, getGroupItems } from '../utils/dataCheck';
import { TagAlert } from '../components/TagAlert';
import { numberCellFormatter, getOfflineTime } from '../utils/dataConverters';
import { MonitoringTagAgGrid } from '../components/MonitoringTagAgGrid';
import { EngineTagAgGrid } from '../components/EngineTagAgGrid';
import { HideTagAgGrid } from '../components/HideTagAgGrid';
import { TextAgGrid } from '../components/TextAgGrid';
import { ValveActionAgGrid } from '../components/ValveActionAgGrid';
import { StatusTagAgGrid } from '../components/StatusTagAgGrid';
import { EmptyCellAgGrid } from '../components/EmptyCellAgGrid';
import { ValveStateAgGrid } from '../components/ValveStateAgGrid';
import { DeviceInfoAgGrid } from '../components/DeviceInfoAgGrid';
import { StatusLineAgGrid } from '../components/StatusLineAgGrid';

export function columnDefsModel(dataViewModel) {
    const columnDefs = [];
    // columnDefs.push(addClimaticColumn("group.lamina", "_"));
    const columns_list = dataViewModel.columns;
    for (const { type, field, headerName, minWidth, hide } of columns_list) {
        columnDefs.push(colModels[type].add(field, headerName, minWidth, hide));
    }
    columnDefs.push({ field: 'alerta', minWidth: 10, hide: true });
    return columnDefs;
}

const colModels = {
    "group": { "add": addColumnGroup },
    "status": { "add": addStatusColumn },
    "status_agg": { "add": addStatusColumnAgg },
    "reference": { "add": addReferenceColumn },
    "reference_agg": { "add": addReferenceColumnAgg },
    "reference_zone_agg": { "add": addReferenceZoneColumnAgg },
    "text_group": { "add": addGroupTextColumn },
    "tag_list_group": { "add": addTagListColumn },
    "tag_line_group": { "add": addTagLineColumn },
    "device_info": { "add": addDeviceInfoColumn },
    "device_info_line": { "add": addDeviceInfoLineColumn },
    "valve_zone": { "add": addValveZoneColumn },
    "text": { "add": addTextColumn },
    "numeric": { "add": addNumericColumn },
    // "numeric_diff":{"add":addNumericDiffColumn } ,
    "range_time": { "add": addRangeTimeColumn },
    "engine": { "add": addEngineColumn },
    "engie": { "add": addEngineColumn },
}

function addEngineColumn(field, headerName, width, hide) {
    return {
        headerName,
        field,
        width,
        maxWidth: width,
        type: 'rightAligned',
        cellRendererSelector: (params) => {
            if (params?.node?.level === 0) {
                const group = getGroupItems(params);
                return EngineTagAgGrid(group);
            } else {
                return EmptyCellAgGrid;
            }
        },
        sortable: true,
        hide
    }
}


function addReferenceColumn(field, headerName, width, hide) {
    return {
        headerName,
        field,
        type: 'numericColumn',
        width,
        minWidth: width,
        cellRendererSelector: (params) => {
            if (params?.value === null) {
                return HideTagAgGrid(field);
            } else {
                return MonitoringTagAgGrid(params, field);
            }
        },
        cellRenderer: 'agAnimateShowChangeCellRenderer',
        valueFormatter: numberCellFormatter,
        sortable: true,
        hide
    }
}


function addReferenceZoneColumnAgg(field, headerName, width, hide) {
    return {
        headerName,
        field,
        type: 'numericColumn',
        width,
        maxWidth: width,
        aggFunc: 'avg',
        cellRendererSelector: (params) => {
            if (params?.value === null) {
                return HideTagAgGrid(field);
            } else {
                return MonitoringTagAgGrid(params, field);
            }
        },
        cellRenderer: 'agAnimateShowChangeCellRenderer',
        valueFormatter: numberCellFormatter,
        sortable: true,
        hide
    }
}



function addReferenceColumnAgg(field, headerName, width, hide) {
    return {
        headerName,
        field,
        type: 'numericColumn',
        width,
        minWidth: width,
        aggFunc: 'avg',
        cellRendererSelector: (params) => {
            if (params?.value === null) {
                return HideTagAgGrid(field);
            } else {
                return MonitoringTagAgGrid(params, field);
            }
        },
        cellRenderer: 'agAnimateShowChangeCellRenderer',
        valueFormatter: numberCellFormatter,
        sortable: true,
        hide
    }
}


function addClimaticColumn(field, headerName, width, hide) {
    return {
        headerName,
        field,
        width,
        minWidth: width,
        cellRendererSelector: (params) => {

            if (isLine(params)) {
                if (params.data.group.lamina > 0) {
                    return {
                        component: () => <TagAlert msg={"Lâmina: " + params.data.group.lamina} tag="pluv" color="dodgerblue" />
                    }
                } else {
                    return EmptyCellAgGrid;
                }
            }

        },
        sortable: true,
        hide
    }
}


function addGroupTextColumn(field, headerName, width, hide) {
    return {
        headerName,
        field: 'group.' + field,
        minWidth: width,
        width: width,
        cellRendererSelector: (params) => {
            if (params?.node?.level === 0) {
                const group = getGroupItems(params);
                return TextAgGrid(group[field]);
            } else {
                return EmptyCellAgGrid;
            }
        },
        sortable: true,
        hide
    }
}


function addDeviceInfoColumn(field, headerName, width, hide) {
    return {
        headerName,
        field: 'group.' + field,
        maxWidth: width,
        width: width,
        cellRendererSelector: (params) => {
            if (params?.node?.level === 0) {
                return DeviceInfoAgGrid(params);
            } else {
                return EmptyCellAgGrid;
            }
        },
        sortable: true,
        hide
    }
}


function addDeviceInfoLineColumn(field, headerName, width, hide) {
    return {
        headerName,
        field: 'group.' + field,
        maxWidth: width,
        width: width,
        cellRendererSelector: (params) => {
            if (isLine(params)) {
                return DeviceInfoAgGrid(params);
            } else {
                return EmptyCellAgGrid;
            }
        },
        sortable: true,
        hide
    }
}


function addTagListColumn(field, headerName, width, hide) {
    return {
        headerName,
        field: 'group.' + field,
        width,
        minWidth: width,
        cellRendererSelector: (params) => {
            if (isGroup(params) && params?.node?.level === 0) {
                const group = getGroupItems(params);
                return ValveActionAgGrid(group[field]);
            } else {
                return EmptyCellAgGrid;
            }
        },
        sortable: true,
        hide
    }
}


function addTagLineColumn(field, headerName, width, hide) {
    return {
        headerName,
        field: 'group.' + field,
        width,
        minWidth: width,
        cellRendererSelector: (params) => {
            if (isGroup(params)) {
                return EmptyCellAgGrid;
            } else {
                return ValveActionAgGrid(params.data.group[field]);
            }
        },
        sortable: true,
        hide
    }
}


function addTextColumn(field, headerName, width, hide) {
    return {
        headerName,
        field,
        width,
        minWidth: width,
        sortable: true,
        hide
    }
}


function addColumnGroup(field, headerName, minWidth, hide) {
    return {
        headerName,
        field,
        enableRowGroup: true,
        rowGroup: true,
        showRowGroup: false,
        width: minWidth,
        minWidth: minWidth,
        hide: true
    }
}

function addNumericColumn(field, headerName, width, hide) {
    return {
        headerName,
        field,
        width,
        minWidth: width,
        type: 'numericColumn',
        sortable: true,
        hide
    }
}


function addRangeTimeColumn(field, headerName, width, hide) {
    return {
        headerName,
        field,
        width,
        minWidth: width,
        valueGetter: getOfflineTime,
        sortable: true,
        hide
    }
}


function addStatusColumnAgg(field, headerName, width, hide) {
    return {
        headerName,
        field,
        width,
        maxWidth: width,
        cellRendererSelector: (params) => {
            return StatusTagAgGrid(params);
        },
        sortable: true,
        hide
    }
}

function addValveZoneColumn(field, headerName, minWidth, hide) {
    return {
        headerName,
        field: 'group.' + field,
        width: minWidth,
        maxWidth: minWidth,
        cellRendererSelector: (params) => {
            if (isSubGroup(params, "zones")) {
                const { value } = isSubGroup(params, "zones");
                return ValveStateAgGrid(value);
            } else {
                if (isGroup(params) && params?.node?.level === 0) {
                    const group = getGroupItems(params);
                    return ValveActionAgGrid(group[field]);
                } else {
                    return EmptyCellAgGrid;
                }
            }
        },
        sortable: true,
        hide
    }
}


function addStatusColumn(field, headerName, width, hide) {
    return {
        headerName,
        field,
        width,
        maxWidth: width,
        cellRendererSelector: (params) => {
            return StatusLineAgGrid(params);
        },
        sortable: true,
        hide
    }
}


// function addNumericDiffColumn(field, headerName, width, hide) {
//     return {
//         headerName,
//         field,
//         minWidth: 50,
//         width: 50,
//         cellRenderer: 'agAnimateShowChangeCellRenderer',
//         type: 'numericColumn',
//         valueFormatter: diferencaFormatter,
//         sortable: true
//     }
// }