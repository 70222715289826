import { TextIconAgGrid } from "./TextIconAgGrid";
import { WarningTagAgGrid } from "./WarningTagAgGrid";
import { OfflineTagAgGrid } from "./OfflineTagAgGrid";
import {
    isOffiline, isLine,
    isGroupWarning, getGroupItems
} from "../utils/dataCheck";
// Componente AgGrid p/ dados gerais do(s) sensor(es), e Utar offline
export function StatusTagAgGrid(params) {
    const row = {
        "group": getGroupItems(params),
        "line": null,
        "icon": "info",
        "txt": null,
        "hint": "Último dado recebido: ",
        "value": null
    };
    if (!row.group) {
        console.log("[StatusTagAgGrid] [group NULL]", params);
        return null;
    }
    // Última telemetria recebida do grupo de sensores
    if (row.group?.lastValueAt) {
        row.hint += row.group.lastValueAt.toLocaleString("pt-BR");
    }
    // Componentes do grupo principal: Offline, Warning totais
    if (params?.node?.level === 0) {
        if (isOffiline(row.group)) {
            return OfflineTagAgGrid(row.hint, row.group.deviceStateAt);
        }
        const warning = isGroupWarning(row.group);
        if (warning) {
            row.hint = `Erros: ${warning.length} \n ${warning.join(", ")} \n\n ${row.hint}`;
            return WarningTagAgGrid(row.hint, []);
        }
    }
    // Componentes de linha: Warning
    row.line = isLine(params);
    if (row.line && row.line.atualizado_br) {
        row.hint = "Último dado recebido: " + row.line.atualizado_br;
        // Quando existir Amostra(sensor), trocar ícone Info pelo número da mesma
        if (row.line?.Amostra) {
            row.icon = null;
            row.txt = row.line.Amostra;
            row.value = row.line.Amostra;
        }
        if (row.line?.warning.length) {
            return WarningTagAgGrid(row.hint, 1, row.value);
        }
    }
    // Componente padrão quando não existe alertas: Info
    return TextIconAgGrid(row.icon, row.hint, row.txt);
}
