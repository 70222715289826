import { TextIconAgGrid } from "./TextIconAgGrid";
import { WarningTagAgGrid } from "./WarningTagAgGrid";
import { OfflineTagAgGrid } from "./OfflineTagAgGrid";
import { isOffiline, isLine,  getGroupItems } from "../utils/dataCheck";
// Componente AgGrid p/ dados gerais do sensor, e Utar offline
export function StatusLineAgGrid(params) {
    const row = {
        "group": getGroupItems(params),
        "line": null,
        "icon": "info",
        "txt": null,
        "hint": "Último dado recebido: ",
        "value": null
    };
    if (!row.group) {
        console.error("StatusLineAgGrid", "group NULL", params);
        return null;
    }
    // Componentes de linha: Warning
    row.line = isLine(params);
    if (row.line && row.line.atualizado_br) {
        if (isOffiline(row.group)) {
            return OfflineTagAgGrid(row.hint, row.group.deviceStateAt);
        }
        row.hint = "Último dado recebido: " + row.line.atualizado_br;
        // Quando existir Amostra(sensor), trocar ícone Info pelo número da mesma
        if (row.line?.Amostra) {
            row.icon = null;
            row.txt = row.line.Amostra;
            row.value = row.line.Amostra;
        }
        if (row.line?.warning.length) {
            return WarningTagAgGrid(row.hint, 1, row.value);
        }
    }
    // Componente padrão quando não existe alertas: Info
    return TextIconAgGrid(row.icon, row.hint, row.txt);
}
