
export function getRowClass(params) {
    let res = classByAlerts["default"];
    if (params.data) {
        if (!statesNotOffline[params.data.group.deviceState]) {
            res = classByAlerts["off-line"];
        } else {
            res = classByAlerts[params.data.alerta];
            if (!res) {

                if (typeof params.data.group?.controleSistema === "number" && params.data.group.controleSistema === 1) {
                    res = classByAlerts["running"];
                    // Conportamento de alerta aplicado a linha
                    // if(lineWarn[params.data.id]
                    //         && devicesWarmLineColor[params.data.id]
                    //         && typeof lineWarn[params.data.id] === "number"
                    //         && Math.round((Date.now() - lineWarn[params.data.id])/1000) > 60 ){
                    //     res = classByAlerts[devicesWarmLineColor[params.data.id]];
                    // }
                }

            }
        }
    }
    return res;
}

const classByAlerts = {
    "smaller": "bg-danger text-white",
    "bigger": "bg-warning text-dark",
    "running": "bg-success text-white",
    "off-line": "bg-secondary text-white",
    "out": "bg-secondary text-white",
    "default": "bg-light text-dark"
};

const statesNotOffline = {
    'running': true,
    'warning': true
}