import { TagIcon } from "./TagIcon"
// Componente AgGrid p/ exibição condicional de ícone ou texto
export function TextIconAgGrid(tag, msg = "", txt) {
    return {
        component: () => (
            <div style={{ paddingBottom: 5, }} title={msg} onClick={()=>alert(msg)}>
                {tag && <TagIcon name={tag} />}
                {txt && 
                    <div style={{ paddingLeft: 7, }} >  
                        {txt}
                    </div>    
                }
            </div>)
    }
}